// 上传合同
<template>
  <div class="examRule">
    <el-dialog title="" :show-close="false" :close-on-click-modal="false" :visible.sync="dialogVisible" width="568px">
      <div class="examRule_head flex ">
        <span>上传合同</span>
        <i class="el-icon-circle-close" @click="close"></i>
      </div>
      <div class="examRule_main p-t-30 p-r-40 p-b-30 p-l-40">
        <div class="borrow-form">
          <el-form ref="form" :rules="rules" class="form" :model="form" label-width="100px">
            <el-form-item label="上传类型:" prop="status">
              <el-select v-model="form.status" placeholder="请选择合同类型">
                <el-option v-for="item in statusList" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="合同编号:" prop="number">
              <el-input v-model="form.number" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="房源编号:" prop="fang_id" v-if="[0,1].includes(form.status)">
              <el-input v-model="form.fang_id" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="租售类型:" prop="fang_type">
              <el-select v-model="form.fang_type" placeholder="请选择房源类型">
                <el-option v-for="item in fang_type_list" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="类型:" prop="fang_types">
              <el-select v-model="form.fang_types" placeholder="请选择类型">
                <el-option v-for="item in zuHousingTypeList" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="签约时间:" prop="qian_times" v-if="[0,1].includes(form.status)">
              <el-date-picker v-model="form.qian_times" type="datetime" format="yyyy-MM-dd HH:mm:ss"
                value-format="timestamp" placeholder="请选择签约时间">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="到期时间:" prop="end_times" v-if="[0,1].includes(form.status)">
              <el-date-picker v-model="form.end_times" type="datetime" format="yyyy-MM-dd HH:mm:ss"
                value-format="timestamp" placeholder="请选择到期时间">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="签约人:" prop="user_name">
              <el-select @change="changeName" clearable filterable remote :remote-method="getxius" v-model="form.user_name" placeholder="请选择签约人">
                <el-option v-for="item in userList" :key="item.user_id" :label="item.name" :value="item.name">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="工号:" prop="uid">
              <el-input disabled v-model="form.uid" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="合同:" prop="contract_image" v-if="[0,1].includes(form.status)">
              <el-upload action="https://xin.xinyoujudichan.com/api/common/upload" list-type="picture-card"
                :on-success="uploadCoverImg" :on-remove="handleRemove">
                <i class="el-icon-plus"></i>
              </el-upload>
            </el-form-item>
          </el-form>
          <div class="interview_main_footer center">
            <el-button @click="submit('form')">提交</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      form: {
        status: '',//0=签约中1=已签约2=报废4=丢失
        number: '',//合同编号
        fang_id: '',//房源id
        fang_type: 2,//房源类型：1=出售2=出租
        fang_types: 2,//1=新房2=住宅3=公寓4=写字楼5=商铺6=别墅zuHousingTypeList
        qian_time: '',//签约时间 时间戳
        qian_times: '',
        end_time: '',//到期时间 时间戳
        end_times: '',
        user_name: '',//签约人
        uid: '',//签约人工号
        contract_image: '',//合同图片 多个用逗号隔开
      },
      fang_type_list: [
        {
          value: 1,
          label: '出售'
        },
        {
          value: 2,
          label: '出租'
        },
      ],
      contract_image: [],
      statusList: [
        {
          value: 0,
          label: '签约中'
        },
        {
          value: 1,
          label: '已签约'
        },
        {
          value: 2,
          label: '报废'
        },
        {
          value: 4,
          label: '丢失'
        }
      ],
      rules: {
        status: [
          { required: true, message: '请选择合同类型', trigger: 'change' }
        ],
        number: [
          { required: true, message: '请输入合同编号', trigger: 'blur' }
        ],
        fang_id: [
          { required: true, message: '请输入房源编号', trigger: 'blur' }
        ],
        qian_times: [
          { type: 'date', required: true, message: '请选择签约时间', trigger: 'change' }
        ],
        end_times: [
          { type: 'date', required: true, message: '请选择到期时间', trigger: 'change' }
        ],
        user_name: [
          { required: true, message: '请选择签约人', trigger: 'change' }
        ],
        contract_image: [
          { required: true, message: '请上传合同' }
        ]
      },
      userList: []
    }
  },
  methods: {
    //选择签约人
    changeName (name) {
      if (this.userList.filter(item=>item.name == name).length) {
        this.form.uid = this.userList.filter(item=>item.name == name)[0].user_id;
      }
    },
    uploadCoverImg(e) {
      this.contract_image.push(e.data.fullurl)
      this.form.contract_image = this.contract_image.join(',')
    },
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.form.qian_time = this.form.qian_times / 1000
          this.form.end_time = this.form.end_times / 1000
          this.$axios.contractUploadAdd(this.form).then(res => {
            this.$message({
              message: res.msg,
              type: 'success'
            });
            this.$emit('success')
            this.dialogVisible = false
          }).catch(err=>{
            console.log(err)
          })
        } else {
          return false;
        }
      });
    },
    handleRemove(e) {
      let data = []
      this.contract_image.forEach(item => {
        if (item != e.response.data.fullurl) {
          data.push(item)
        }
      })
      this.form.contract_image = data.join(',')
    },
    //关闭
    close() {
      this.dialogVisible = false
    },
    getxius(e) {
      this.$axios
        .userList({
          name: e,
          position: 4,
        })
        .then((res) => {
          this.userList = res.data;
        });
    },
    //打开
    open() {
      this.getxius('');
      this.dialogVisible = true
    }
  }
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog__header,
/deep/ .el-dialog__body {
  padding: 0 !important;
}

/deep/ .el-dialog {
  border-radius: 8px;
  overflow: hidden
}

.interview_main_footer {
  padding: 30px 0 40px 0;

  /deep/ .el-button {
    width: 480px;
    height: 56px;
    color: #fff;
    font-size: 18px;
    background: #3273f6;
    border-radius: 28px;
  }
}

.el-select,
.el-input {
  width: 100%;
}
</style>
